/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '@rma/generic/util/environment';
import 'zone.js/plugins/zone-error';

// If you are adding something here, don't forget to add the key to: Infrastructure\public\RMA.Public.UI.Infrastructure\environment-settings.json
export const aus_environment: Environment = {
   type: 'develop',
  baseUrl: 'https://dev.ratemyagent.com.au',
  brokerUiUrl: 'https://devbrokers.ratemyagent.com.au/',
  domain: 'ratemyagent.com.au',
  googleMapKey: 'AIzaSyCcS9T2CVJ4P_e69LgbfldbQSQ7fXMColk',
  countryCode: 'AU',
  launchDarklyClientId: '586b1ec99ae5520918642b10',
  recaptchaSiteKey: '6LcoewYTAAAAALaxzBupK5p2kI5UCj5-qifK661M',
  securePrivacyApiKey: '',
  securePrivacyId: '',
  version: '1',
  api: {
    apiUrl: 'https://devapi.ratemyagent.com.au',
    reviewsApiUrl: 'https://reviews.dev.ratemyagent.com.au',
    socialApiUrl: 'https://social.dev.ratemyagent.com.au',
    surveyApiUrl: 'https://surveyapi.dev.ratemyagent.com',
    billingApiUrl: 'https://billing.dev.ratemyagent.com.au',
    emailApiUrl: 'https://email.dev.ratemyagent.com.au',
    findMyAgentApiUrl: 'https://findmyagent.dev.ratemyagent.com.au',
  },
  social: {
    facebookId: '345975122719921',
    facebookUrl: 'https://www.facebook.com/ratemyagent',
    twitterName: '@RateMyAgent_AUS',
    twitterUrl: 'https://twitter.com/ratemyagent_aus',
    linkedinUrl: 'http://www.linkedin.com/company/rate-my-agent',
    instagramUrl: 'https://www.instagram.com/ratemyagent_aus/',
  },
  assets: {
    cdnUrl: 'https://cdn.ratemyagent.com.au/',
    staticUrl: 'https://static.ratemyagent.com.au',
    cloudinaryBase: 'https://images.ratemyagent.com/ratemyagent/image/upload/',
    cloudinarySuffix: '/cdn/',
  },
  identity: {
    url: 'https://identity.dev.ratemyagent.com',
    client: 'rma.au.ui',
    secret: 'D1g1t@lc@stle',
    jwtEnvironment: 'AU_DevPreprod',
  },
  tracking: {
    hotjarId: undefined,
    heapAppId: '3713477979',
    facebookPixelId: undefined,
    googleTagManagerWebId: 'GTM-5JJB8R',
    googleTagManagerMegaphone: undefined,
    googleAnalytics: {
      optimizeContainerId: 'GTM-N47XBV2',
      optimizeMeasurementId: 'UA-41500364-1',
    },
    googleGlobalSiteTag: {
      adConversionId: undefined,
      phoneRevealAdConversionLabel: undefined,
      callClickAdConversionLabel: undefined,
      smsClickAdConversionLabel: undefined,
      contactFormCompletionAdConversionLabel: undefined,
    },
  },
  language: {
    lokaliseAppId: undefined,
    locale: 'en-AU',
    supportedLocales: [],
    url: 'https://language.ratemyagent.com',
  },
};

export const usa_environment: Environment = {
  ...aus_environment,
  baseUrl: 'https://dev.ratemyagent.com',
  countryCode: 'US',
  launchDarklyClientId: '586b1eef9ae5520918642b16',
  api: {
    apiUrl: 'https://devapi.ratemyagent.com',
    reviewsApiUrl: 'https://reviews.dev.ratemyagent.com',
    socialApiUrl: 'https://social.dev.ratemyagent.com',
    surveyApiUrl: 'https://surveyapi.dev.ratemyagent.com',
    billingApiUrl: 'https://billing.dev.ratemyagent.com',
    emailApiUrl: 'https://email.dev.ratemyagent.com',
    findMyAgentApiUrl: 'https://findmyagent.dev.ratemyagent.com.au',
  },
  assets: {
    ...aus_environment.assets,
    staticUrl: 'https://static.ratemyagent.com',
    cdnUrl: 'https://cdn.ratemyagent.com/',
    cloudinarySuffix: '/cdn-usa/',
  },
  identity: {
    ...aus_environment.identity,
    client: 'rma.us.ui',
    jwtEnvironment: 'US_DevPreprod',
  },
  language: {
    ...aus_environment.language,
    locale: 'en-US',
  },
};

export const nz_environment: Environment = {
  ...aus_environment,
  baseUrl: 'https://dev.ratemyagent.co.nz',
  countryCode: 'NZ',
  launchDarklyClientId: '5ac2fa3cbbbd182ca18d506d',
  api: {
    apiUrl: 'https://devapi.ratemyagent.co.nz',
    reviewsApiUrl: 'https://reviews.dev.ratemyagent.co.nz',
    socialApiUrl: 'https://social.dev.ratemyagent.co.nz',
    surveyApiUrl: 'https://surveyapi.dev.ratemyagent.com',
    billingApiUrl: 'https://billing.dev.ratemyagent.co.nz',
    emailApiUrl: 'https://email.dev.ratemyagent.co.nz',
    findMyAgentApiUrl: 'https://findmyagent.dev.ratemyagent.co.nz',
  },
  assets: {
    ...aus_environment.assets,
    staticUrl: 'https://static.ratemyagent.co.nz',
    cdnUrl: 'https://cdn.ratemyagent.co.nz/',
    cloudinarySuffix: '/cdn-nz/',
  },
  identity: {
    ...aus_environment.identity,
    client: 'rma.nz.ui',
    jwtEnvironment: 'NZ_DevPreprod',
  },
  language: {
    ...aus_environment.language,
    locale: 'en-NZ',
  },
};

export const local_environment: Environment = {
  ...aus_environment,
  baseUrl: 'https://local.ratemyagent.com',
  api: {
    ...aus_environment.api,
    apiUrl: 'https://localwebapi.ratemyagent.com',
  },
};
